@import '~bootstrap/scss/bootstrap.scss';

h4 {
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-left: solid 12px #fac043;
}

.modeDisabled {
  border-left: solid 12px gray("400");
}

.infoGroup {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.infoGroup > .prepend {
  margin-right: -1px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: gray("800");
  text-align: center;
  white-space: nowrap;
  background-color: gray("200");
  border: 1px solid gray("400");
  border-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.infoGroup > .info {
  flex: 1 1 auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: gray("800");
  text-align: center;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid gray("400");
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.time {
  flex: 1 1 auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: gray("800");
  text-align: center;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid gray("400");
  border-radius: 0.25rem;
}

th:nth-child(1) {
  width: 6rem;
}

th:nth-child(5) {
  width: 12rem;
}

.icon {
  font-size: 12px;
}
